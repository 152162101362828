import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import {
  AppBar,
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { LoginApple } from '../components/auth/login-apple';
import { LoginJwt } from '../components/auth/login-jwt';
import { Logo } from '../components/logo';
import { useSettings } from '../contexts/settings-context';
// import gtm from '../lib/gtm';

export const Login: FC = () => {
  const { settings } = useSettings();
  const { t } = useTranslation();

  // useEffect(() => {
  //   gtm.push({ event: 'page_view' });
  // }, []);

  return (
    <>
      <Helmet>
        <title>{`${t('Login')} | ${t('Authentique')}`}</title>
      </Helmet>
      <AppBar elevation={0} sx={{ backgroundColor: 'background.paper' }}>
        <Container maxWidth="md">
          <Toolbar disableGutters sx={{ height: 64 }}>
            <RouterLink to="/">
              <Logo variant={settings.theme === 'dark' ? 'light' : 'dark'} />
            </RouterLink>
          </Toolbar>
        </Container>
      </AppBar>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          pt: '64px',
        }}
      >
        <Box sx={{ py: 9 }}>
          <Container maxWidth="md">
            <Grid container spacing={6}>
              <Grid item md={6} xs={12}>
                <Card sx={{ backgroundColor: 'background.default' }} elevation={0}>
                  <CardContent>
                    <LoginJwt />
                    <Typography variant="body1" fontSize={14} align="center" my={2}>
                      Or
                    </Typography>
                    <LoginApple />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </>
  );
};
